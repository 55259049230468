import styled from '@emotion/styled';
import { APP_IMG } from 'src/constants';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

const NewWidgetTranslate = ({ selectLang, handleTranslate, eventDataLayer, isTranslated }) => {
  const langList = isTranslated ? [
    { id: 'TH', name: 'TH', desc: 'ภาษาไทย', image: '/images/translate/THB.png' },
    { id: 'EN', name: 'EN', desc: 'ภาษาอังกฤษ', image: '/images/translate/USD.png' },
    { id: 'CN', name: 'CN', desc: 'ภาษาจีน', image: '/images/translate/CNY.png' }
  ] : [
    { id: 'TH', name: 'TH', desc: 'ภาษาไทย', image: '/images/translate/THB.png' }
  ];
  const handleClickItem = item => {
    handleTranslate(item.id);
  };

  return (
    <WidgetWrapper className='item-wrapper'>
      {langList.map((item, index) => (
        <div
          className={`lang-item ${selectLang === item.id ? 'selected' : 'unselected'}`}
          key={index}
          onClick={() => {
            handleClickItem(item);
            // ! DATALAYER
            sendDataLayerGlobal({
              ...eventDataLayer,
              data: {
                ...eventDataLayer.data,
                title: item.name,
                index: index + 1
              }
            });
          }}
        >
          <img width={50} height={50} src={`${APP_IMG}${item.image}`} />
          {/* <div className='text'>{item.name}</div> */}
        </div>
      ))}
    </WidgetWrapper>
  );
};
const WidgetWrapper = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  flex-wrap: nowrap;
  padding: 5px 0;
  ::-webkit-scrollbar {
    height: 4px;
    /* width: 3px; */

    background-color: #f5f5f58a;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 10px;
    background-color: #f5f5f58a;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0600E7;
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    width: 30%;
    overflow: auto;
  }
  @media (max-width: 690px) {
    width: 40%;
  }
  .selected {
    img {
      border: 2px solid #000;
    }
  }
  .unselected {
    opacity: 0.5;
  }
  .lang-item {
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      @media (max-width: 690px) {
        width: 35px;
        height: 35px;
      }
      @media (max-width: 475px) {
        width: 30px;
        height: 30px;
      }
    }
    .text {
      font-size: 0.65rem;
      padding-top: 4px;
    }
    &:hover {
      /* background-color: rgba(31, 41, 154, 0.1); */
      opacity: 0.8;
    }
  }
`;
export default NewWidgetTranslate;
