import styled from '@emotion/styled';
import _ from 'lodash';
import { memo } from 'react';
import { SHOW_COMMEMORATE } from 'src/constants';
import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
import NewWidgetSpeech from './new-widget-speech';
import NewWidgetTranslate from './new-widget-translate';
import WidgetShareSticky from './widget-share-sticky';

const WidgetStickyContent = ({ selectLang, isLoading, article, className = '', router = null, handleTranslate, isTranslated }) => {
  if (_.isEmpty(article)) return null;
  return (
    <>
      <WidgetWrapper id='widget-contents' className={`${className}`}>
        <div
          id={`${SHOW_COMMEMORATE === 'true' ? 'widget-group-js' : 'widget-group'}`}
          className={`${SHOW_COMMEMORATE === 'true' ? 'position-sticky-left-content-js' : 'position-sticky-left-content'}  back-drops`}
        >
          <div className='share-wrapper'>
            <NewWidgetTranslate
            isTranslated={isTranslated}
              article={article}
              selectLang={selectLang}
              handleTranslate={handleTranslate} // ! DATALAYER
              eventDataLayer={{
                type: DATALAYER_TYPE.TRACK_POSITION,
                router: router?.pathname,
                section: 'แปลภาษา',
                position: 'ใต้รูปภาพปก:content',
                data: {
                  heading: 'แปลภาษา',
                  block: '1'
                }
              }}
            />
            <NewWidgetSpeech selectLang={selectLang} article={article} loadingLang={isLoading}
              eventDataLayer={{
                type: DATALAYER_TYPE.TRACK_POSITION,
                router: router?.pathname,
                section: 'ฟังเสียงอ่าน',
                position: 'ใต้รูปภาพปก:content',
                data: {
                  heading: 'ฟังเสียงอ่าน',
                  block: '1'
                }
              }} />
            <WidgetShareSticky
              article={article}
              link={article.link}
              // ! DATALAYER
              eventDataLayer={{
                type: DATALAYER_TYPE.TRACK_POSITION,
                router: router?.pathname,
                section: 'แชร์ข่าว',
                position: 'ด้านซ้าย:content',
                data: {
                  heading: 'แชร์ข่าว'
                }
              }}
            />
          </div>
        </div>
      </WidgetWrapper>
    </>
  );
};

const WidgetWrapper = styled.div`
  &.block-sticky {
    position: sticky;
    top: 0;
    display: block;
    background-color: #fff;
    text-align: center;
    z-index: 2;
    /* margin-top: -15px; */
    /* @media (max-width: 768px) {
      width: 0;
    } */
  }
  .widget-share {
    margin: 0;
  }
  .share-wrapper {
    position: relative;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    @media (max-width: 992px) {
      width: 100%;
      /* gap: 8px; */
    }
    @media (max-width: 920px) {
      padding-bottom: unset;
    }
    .disable-pointer {
      pointer-events: none;
    }
  }

  .position-sticky-left-content {
    width: 100%;
    margin: auto;
    position: sticky;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 10px 0px;
    border-radius: 0 0 20px 20px;
    box-shadow: 0 8px 8px -5px rgba(0, 0, 0, 0.4);
    top: 50px;
    left: -80px;
    &.back-drops {
      margin-bottom: 10px;
      background-color: white;
    }
    @media (max-width: 1024px) {
      position: fixed;
      top: 75px;
      left: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5px;
      width: 100% !important;
      min-height: 40px;
      border-radius: unset;
    }
    @media (min-width: 690px) and (max-width:767px) {
      position: fixed;
      top: 75px;
      left: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5px;
      width: 100% !important;
      min-height: 40px;
      border-radius: unset;
    }
    @media (max-width: 690px) {
      position: fixed;
      top: 105px;
      left: 0;
      padding-bottom: 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5px;
      width: 100% !important;
      min-height: 40px;
      border-radius: unset;
    }
  }

  @media (max-width: 768px) {
    width: 0;
  }
`;

export default memo(WidgetStickyContent);
