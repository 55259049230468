import styled from '@emotion/styled';
import axios from 'axios';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { FaPause, FaPlay } from 'react-icons/fa';
import {
  APP_IMG,
  NEXT_PUBLIC_ID_MOCK_SOUND,
  NEXT_PUBLIC_PLACEHOLDER_TIME_OUT,
  NEXT_PUBLIC_PLACEHOLDER_TITLE_SUOND,
  NEXT_PUBLIC_URL_PATH_SOUND,
  NEXT_PUBLIC_URL_SOUND,
  NEXT_PUBLIC_WEB_NAME_SOUND
} from 'src/constants';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import useResponsive from 'src/utils/devices';

function NewWidgetSpeech({ selectLang, article, loadingLang, _data, eventDataLayer }) {
  const [isFetch, setIsFetch] = useState(true);
  const [audio, setAudio] = useState([]);
  const [audioEpisode, setAudioEpisode] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [gender, setGender] = useState(false);
  const [startMinute, setStartMinute] = useState(0);
  const [startSeconde, setStartSeconde] = useState(0);
  const [endMinute, setEndMinute] = useState(0);
  const [endSeconde, setEndSeconde] = useState(0);
  const [episode, setEpisode] = useState(1);
  const [nextEpisode, setNextEpisode] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const router = useRouter();
  const audioRef = useRef();
  const { isMobile } = useResponsive();
  const _id = article?.id;

  let minutesTime;
  let secondsTime;
  let endMinutesTime;
  let endSecondsTime;

  const getTK = async () => {
    const uriToken = `${NEXT_PUBLIC_URL_SOUND}/create`;
    const getToken = await axios
      .post(
        uriToken,
        {
          app: 'default'
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch(error => {
        console.error(error);
      });
    return getToken;
  };

  const handleAudio = async (_gender, _checkGander = false, _switchGender = true, _isFirstPage = false) => {
    if (_isFirstPage) return;
    try {
      setIsLoading(false);
      const getToken = await getTK();
      if (getToken?.data?.data?.token !== undefined) {
        const response = await axios.post(
          `${NEXT_PUBLIC_URL_SOUND}${NEXT_PUBLIC_URL_PATH_SOUND}`,
          {
            site: NEXT_PUBLIC_WEB_NAME_SOUND,
            newsid: `${_id || NEXT_PUBLIC_ID_MOCK_SOUND}`,
            sex: _gender ? 'M' : 'F',
            episode: `${episode}`,
            language: selectLang
          },
          {
            headers: {
              accessToken: getToken?.data?.data?.token,
              accessTimeStamp: getToken?.data?.data?.timestamp
            }
          }
        );
        const data = response.data;
        if (!_.isEmpty(data)) {
          window.localStorage.setItem('audioBackup', JSON.stringify(data));
          setAudio(data);
          setIsLoading(true);
          if (_checkGander) {
            handlePlayAudio(_gender, _switchGender);
          }
        } else {
          setAudio(null);
          setIsLoading(true);
        }
      } else {
        setAudio(null);
        setIsLoading(true);
      }
    } catch (error) {
      setAudio(null);
      setIsLoading(true);
      console.log(error, 'error audio');
    }
  };

  const handleAudioFetchNextEpisode = async _gender => {
    try {
      const getToken = await getTK();
      const response = await axios.post(
        `${NEXT_PUBLIC_URL_SOUND}${NEXT_PUBLIC_URL_PATH_SOUND}`,
        {
          site: NEXT_PUBLIC_WEB_NAME_SOUND,
          newsid: `${_id || NEXT_PUBLIC_ID_MOCK_SOUND}`,
          sex: _gender ? 'M' : 'F',
          episode: `${episode !== 3 ? episode + 1 : episode}`,
          language: selectLang
        },
        {
          headers: {
            accessToken: getToken?.data?.data?.token,
            accessTimeStamp: getToken?.data?.data?.timestamp
          }
        }
      );
      if (!_.isEmpty(response.data)) {
        if (episode === 2 || episode === 3) {
          setNextEpisode(false);
        }
        setAudioEpisode(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePlayAudio = async (_gender = null, _switchGender = true) => {
    setIsLoading(true);
    setIsFirstPage(false);
    if (isFetch) {
      // eslint-disable-next-line promise/param-names
      await new Promise(e => setTimeout(e, 0));
      await handleAudio(_gender, false);
      setIsFetch(false);
    }
    // eslint-disable-next-line promise/param-names
    await new Promise(e => setTimeout(e, 0));
    if (!_.isEmpty(audioRef.current)) {
      audioRef.current.volume = 0.5;
      if (currentTime === 0) {
        audioRef.current.paused ? audioRef.current.play() : audioRef.current.pause();
        setIsPlaying(true);
      } else {
        audioRef.current.currentTime = currentTime;
        audioRef.current.paused ? audioRef.current.play() : audioRef.current.pause();
        setIsPlaying(true);
      }
      // isFetch
      if (!_switchGender && episode !== 3) {
        if (!_.isEmpty(article?.content_II)) {
          await new Promise(resolve => setTimeout(resolve, isFetch ? 5000 : 500));
          handleAudioFetchNextEpisode(_gender);
        }
      }
    }
  };

  const handlePauseAudio = async () => {
    if (!_.isEmpty(audioRef.current)) {
      if (currentTime === 0) {
        audioRef.current.pause();
      } else {
        audioRef.current.currentTime = currentTime;
        audioRef.current.pause();
      }
      sendDataLayerGlobal({
        ...eventDataLayer,
        type: DATALAYER_TYPE.TRACK_READTIME,
        data: {
          title: router.asPath,
          heading: gender ? `click-ฟังเสียงผู้ชาย-${selectLang}` : `click-ฟังเสียงผู้หญิง-${selectLang}`,
          time: `0:00 - ${startMinute}:${startSeconde.toString().length <= 1 ? `0${startSeconde}` : startSeconde}`
        }
      });
    }
    setIsPlaying(false);
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!_.isEmpty(audioRef.current)) {
        minutesTime = Math.floor(audioRef.current?.currentTime / 60);
        secondsTime = Math.floor(audioRef.current?.currentTime % 60);
        endMinutesTime = Math.floor(audioRef.current?.duration / 60);
        endSecondsTime = Math.floor(audioRef.current?.duration % 60);
        if (audioRef.current?.currentTime !== audioRef.current?.duration) {
          setStartMinute(isNaN(minutesTime) ? 0 : minutesTime);
          setStartSeconde(isNaN(secondsTime) ? 0 : secondsTime);
          // end
          setEndMinute(isNaN(endMinutesTime) ? 0 : endMinutesTime);
          setEndSeconde(isNaN(endSecondsTime) ? 0 : endSecondsTime);
          setCurrentTime(audioRef.current.currentTime);
        }
      }
    }, 500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    handlePauseAudio();
    setIsFetch(true);
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
    }
    setStartMinute(0);
    setStartSeconde(0);
    // end
    setEndMinute(0);
    setEndSeconde(0);
  }, [selectLang]);

  const checkFirstRender = useRef(true);
  const [st, setSt] = useState(null);
  useEffect(() => {
    if (checkFirstRender.current) {
      checkFirstRender.current = false;
      return;
    }
    handleAudioFetchNextEpisode(gender);
  }, [st]);
  const audioSrc = audio?.url;
  const handleAudioEnded = async () => {
    if (!_.isEmpty(article?.content_II)) {
      if (audio?.url !== audioEpisode.url && !nextEpisode && (_.isEmpty(audioEpisode?.url?.split('-3')?.[1]) || _.isEmpty(audio?.url?.split('-3')?.[1]))) {
        setEpisode(2);
        setAudio(audioEpisode);
        setNextEpisode(true);
        audioRef.current.currentTime = 0;
        audioRef.current.play();
        if (!_.isEmpty(article?.content_III)) {
          if (minutesTime === endMinutesTime && secondsTime === endSecondsTime && episode === 2) {
            setEpisode(3);
          }
          await new Promise(resolve => setTimeout(resolve, 5000));
          setSt(new Date());
        }
      } else {
        const audioLocal = window?.localStorage?.getItem('audioBackup') ? JSON.parse(window?.localStorage?.getItem('audioBackup') || '') : [];
        setNextEpisode(false);
        setAudio({ url: audioLocal?.url?.replace('-2', '-1') });
        setAudio({ url: audioLocal?.url?.replace('-3', '-1') });
        setEpisode(1);
        audioRef.current.currentTime = 0;
        audioRef.current.pause();
        setIsPlaying(false);
        setIsFetch(true);
      }
    } else {
      setEpisode(1);
      audioRef.current.currentTime = 0;
      audioRef.current.pause();
      setIsPlaying(false);
      setIsFetch(true);
    }
  };

  useEffect(() => {
    const handleTabClose = () => {
      handlePauseAudio();
    };
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  useEffect(() => {
    if (NEXT_PUBLIC_PLACEHOLDER_TITLE_SUOND !== '') {
      const findById = document.getElementById('placeholder-title-id');
      if (!_.isEmpty(findById)) {
        findById?.setAttribute('class', 'sound-placeholder-title active-placeholder');
        setTimeout(() => {
          findById?.setAttribute('class', 'sound-placeholder-title active-placeholder disable-placeholder');
        }, parseInt(NEXT_PUBLIC_PLACEHOLDER_TIME_OUT) || 1000);
        setTimeout(() => {
          findById?.setAttribute('style', 'display: none;');
        }, parseInt(NEXT_PUBLIC_PLACEHOLDER_TIME_OUT) + 1000 || 2000);
      }
    }
    setIsFirstPage(true);
  }, [router.isPreview]);

  return (
    <Wrapper man={`${APP_IMG}/images/M12.png`} woman={`${APP_IMG}/images/F12.png`} className={`${loadingLang ? 'disable-pointer' : ''}`}>
      <div className={`sound-slice close-sound-ui ${audio === null ? 'audio-null' : ''} ${isLoading ? 'loading-mock' : ''}`}>
        <div className='sound-flex'>
          {audio === null ? (
            <>
              <p></p>
              <p className='empty-data'>Not Found</p>
              <p></p>
            </>
          ) : (
            <div className='audio-group'>
              <div className='audio-controls'>
                <label id='switch' className={'switch'}>
                  {gender ? (
                    <input
                      onClick={async () => {
                        setGender(prev => !prev);
                        handleAudio(!gender, true, false, isFirstPage);
                        sendDataLayerGlobal({
                          ...eventDataLayer,
                          data: {
                            ...eventDataLayer?.data,
                            heading: `เสียงอ่านผู้หญิง-${selectLang}`,
                            title: article.title,
                            index: 1
                          }
                        });
                      }}
                      type='checkbox'
                      id='slider'
                    />
                  ) : (
                    <input
                      onClick={async () => {
                        setGender(prev => !prev);
                        // setIsFirstPage(false);
                        handleAudio(!gender, true, false, isFirstPage);
                        sendDataLayerGlobal({
                          ...eventDataLayer,
                          data: {
                            ...eventDataLayer?.data,
                            heading: `เสียงอ่านผู้ชาย-${selectLang}`,
                            title: article.title,
                            index: 1
                          }
                        });
                      }}
                      type='checkbox'
                      id='slider'
                      checked
                    />
                  )}
                  <span className='slider round'></span>
                </label>
                {!isPlaying ? (
                  <ButtonPlay
                    className='btn-main-bottom font-poppins'
                    onClick={() => {
                      handlePlayAudio(gender, false);
                      // ! DATALAYER
                      sendDataLayerGlobal({
                        ...eventDataLayer,
                        data: {
                          ...eventDataLayer?.data,
                          heading: `เสียงอ่าน${gender ? 'ผู้ชาย' : 'ผู้หญิง'}-${selectLang}`,
                          title: article.title,
                          index: 1
                        }
                      });
                    }}
                  >
                    <FaPlay size={18} color='#fff' className='btn-play-sound' />
                    {!isMobile && <div className='text'>Play</div>}
                  </ButtonPlay>
                ) : (
                  <ButtonPlay className='btn-main-bottom font-poppins' onClick={() => handlePauseAudio()}>
                    <FaPause size={18} color='#fff' className='btn-pause-sound' />
                    {!isMobile && <div className='text'>Pause</div>}
                  </ButtonPlay>
                )}
                {isFirstPage && (
                  <div className='status-wrapper'>
                    <NewWaveAnimate className='wave-wrapper --default-wave'>
                      {new Array(20).fill('').map((item, index) => (
                        <NewWaveItem key={index} className={!isPlaying ? 'close-animation' : ''}></NewWaveItem>
                      ))}
                    </NewWaveAnimate>
                  </div>
                )}
                {isLoading ? (
                  <>
                    {!isFirstPage && (
                      <div className='status-wrapper'>
                        <NewWaveAnimate className='wave-wrapper'>
                          {new Array(20).fill('').map((item, index) => (
                            <NewWaveItem key={index} className={!isPlaying ? 'close-animation' : ''}></NewWaveItem>
                          ))}
                        </NewWaveAnimate>

                        {isLoading && (
                          <div className='audio-status font-poppins'>
                            <label>
                              {startMinute}:{startSeconde.toString().length <= 1 ? `0${startSeconde}` : startSeconde}
                            </label>{' '}
                            /{' '}
                            <label>
                              {endMinute}:{endSeconde.toString().length <= 1 ? `0${endSeconde}` : endSeconde}
                            </label>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {!isFirstPage && (
                      <div className='ring-wrapper'>
                        <div className='ring-group'>
                          <div className='lds-ring'>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <label>Loading...</label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {audio && isLoading && <audio ref={audioRef} id='myAudio' src={audioSrc} muted />}
      {audio && isLoading && (
        <ReactAudioPlayer
          src={audioSrc}
          // controls
          onEnded={handleAudioEnded}
          ref={ref => {
            if (!ref) return;
            audioRef.current = ref.audioEl.current;
          }}
        />
      )}
    </Wrapper>
  );
}
const ButtonPlay = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  padding-left: 12px;
  background-color: var(--quaternary-color);
  border-radius: 10px;
  padding: 10px;
  .text {
    width: fit-content;
    font-size: 0.8125rem;
    font-weight: 600;
    color: #fff;
    @media (max-width: 768px) {
      width: 70%;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 80px;
    margin-right: 10px;
    gap: 2px;
  }
`;
const NewWaveAnimate = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  &.--default-wave {
    height: 30px;
  }
`;
const NewWaveItem = styled.div`
  display: block;
  position: relative;
  background: var(--quaternary-color);
  height: 100%;
  width: 2px;
  margin: 0 1px;
  animation: animate 1.2s linear infinite;
  @keyframes animate {
    10% {
      height: 30%;
    }
    30% {
      height: 100%;
    }
    60% {
      height: 50%;
    }
    80% {
      height: 75%;
    }
    100% {
      height: 20%;
    }
  }
  &.close-animation {
    animation: none !important;
  }
  &:nth-of-type(1) {
    animation-delay: 0;
    height: 40%;
  }
  &:nth-of-type(2) {
    animation-delay: -2.4s;
    height: 60%;
  }
  &:nth-of-type(3) {
    animation-delay: -4.7s;
  }
  &:nth-of-type(4) {
    animation-delay: -5.8s;
    height: 60%;
  }
  &:nth-of-type(5) {
    animation-delay: -6.4s;
    height: 40%;
  }
  &:nth-of-type(6) {
    animation-delay: -7.5s;
  }
  &:nth-of-type(7) {
    animation-delay: -8.2s;
  }
  &:nth-of-type(8) {
    animation-delay: -9.6s;
    height: 40%;
  }
  &:nth-of-type(9) {
    animation-delay: -10.5s;
  }
  &:nth-of-type(10) {
    animation-delay: 2.7s;
    height: 60%;
  }
  &:nth-of-type(11) {
    animation-delay: 0;
    height: 40%;
  }
  &:nth-of-type(12) {
    animation-delay: -2.4s;
  }
  &:nth-of-type(13) {
    animation-delay: -4.7s;
  }
  &:nth-of-type(14) {
    animation-delay: -5.8s;
    height: 60%;
  }
  &:nth-of-type(15) {
    animation-delay: -6.4s;
    height: 40%;
  }
  &:nth-of-type(16) {
    animation-delay: -7.5s;
  }
  &:nth-of-type(17) {
    animation-delay: -8.2s;
    height: 60%;
  }
  &:nth-of-type(18) {
    animation-delay: -9.6s;
    height: 40%;
  }
  &:nth-of-type(19) {
    animation-delay: -10.5s;
  }
  &:nth-of-type(20) {
    animation-delay: 2.7s;
    height: 60%;
  }
`;

const Wrapper = styled.div`
  text-align: center;
  background-color: #fff;
  width: fit-content;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  width: 100%;
  @media (max-width: 690px) {
    width: 60%;
  }
  &.is-open {
    border-radius: unset;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .sound-placeholder-title {
    position: absolute;
    right: -123px;
    top: 14px;
    transition: all 0.3s ease-in-out;
    @media (max-width: 768px) {
      left: -123px;
      right: unset;
    }
    &.disable-placeholder {
      opacity: 0 !important;
      ::before {
        content: '';
        opacity: 0 !important;
      }
    }
    &.active-placeholder {
      background-color: var(--quaternary-color);
      padding: 2px 10px;
      border-radius: 5px;
      opacity: 1;

      &::before {
        content: '';
        opacity: 1;
        position: absolute;
        top: 4px;
        left: -10px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid var(--quaternary-color);
        @media (max-width: 768px) {
          right: -10px;
          left: unset;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 0 10px 10px;
          border-color: transparent transparent transparent var(--quaternary-color);
        }
      }
    }
    label {
      color: #fff;
      font-size: 16px;
      font-weight: 300;
    }
  }
  .btn-main-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 35px;
    margin: 0 5px;
    border-radius: 50px;
    @media (max-width: 992px) {
      max-width: 100px;
    }
    @media (max-width: 768px) {
      max-width: 100px;
    }
    @media (max-width: 480px) {
      max-width: 50px;
    }
  }
  .disable-button {
    cursor: crosshair;
    pointer-events: none;
    opacity: 0.7;
  }

  .audio-group {
    display: flex;
    width: 100%;
    flex-direction: column;

    .share.header {
      position: absolute;
      top: -15px;
      right: -10px;
      .share.header-image {
        flex-shrink: 0;
        .resolution-image {
          position: relative;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          margin: auto;
          @media (max-width: 690px) {
            width: 35px;
            height: 35px;
          }
          ::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #fff;
          }
        }
        img {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          object-fit: cover;
        }
      }
    }
  }
  .status-wrapper {
    display: block;
    width: 100%;
    position: relative;
    @media (max-width: 992px) {
      width: 70%;
      margin: 0 5px;
    }
    @media (max-width: 767px) {
      width: 30%;
      margin: 0 5px;
    }
  }
  .audio-status {
    color: var(--quaternary-color);
    font-weight: 400;
    font-size: 0.615rem;
    padding-top: 2px;
  }
  .audio-controls {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    @media (min-width: 992px) {
      display: grid;
      grid-template-columns: 100px 80px 2fr;
      /* grid-template-columns: 100px 2fr 100px; */
    }
    @media (max-width: 992px) {
      align-items: center;
      flex-direction: row;
      gap: 5px;
      grid-template-columns: 1fr;
    }
  }
  .loading-control {
    width: 100%;
    grid-template-columns: 1fr;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    .lds-ring {
      div {
        top: 10%;
        left: 40%;
      }
    }
  }

  .btn-play-sound {
    width: 12px;
    font-size: 28px;
  }
  .btn-pause-sound {
    width: 12px;
    font-size: 28px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 35px;
    @media (max-width: 992px) {
      width: 120px;
      margin: unset;
    }
    @media (max-width: 690px) {
      width: 70px;
    }
    p {
      margin-top: 2px;
      font-size: 12px;
    }
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--quaternary-color);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50px;
  }
  .slider:after {
    position: absolute;
    content: 'Male';
    top: 50%;
    left: auto;
    right: 8px;
    transform: translate(0%, -50%);
    color: #fff;
    @media (max-width: 768px) {
      font-size: 1rem;
      font-weight: 600;
      right: 20%;
    }
    @media (max-width: 690px) {
      content: 'M';
    }
    @media (max-width: 380px) {
      right: 10%;
    }
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 35px;
    width: 35px;
    left: 0;
    bottom: 0;
    top: 0;
    margin: auto 0;
    transition: 0.4s;
    box-shadow: 0 0px 15px #2020203d;
    background: white url(${props => props.man});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid #000;
    @media (max-width: 690px) {
      border: 1px solid var(--quaternary-color);
      background-color: #fff;
      color: var(--quaternary-color);
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  input:checked + .slider {
    //background-color: var(--quaternary-color);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--quaternary-color);
  }
  input:checked + .slider:after {
    position: absolute;
    content: 'Female';
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    color: #fff;
    @media (max-width: 690px) {
      content: 'F';
      left: 30%;
    }
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(200%);
    -ms-transform: translateX(200%);
    transform: translateX(200%);
    background: white url(${props => props.woman});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: 768px) {
      transform: translateX(197%);
    }
    @media (max-width: 690px) {
      transform: translateX(100%);
      border-radius: 0 50% 50% 0;
    }
    @media (max-width: 475px) {
      transform: translateX(100%);
    }
    @media (max-width: 370px) {
      transform: translateX(144%);
    }
  }

  /* Rounded sliders */
  //.slider.round {
  //border-radius: 50px;
  //}

  .slider.round:before {
    border-radius: 50%;
    @media (max-width: 690px) {
      border-radius: 50% 0 0 50%;
    }
  }

  svg {
    cursor: pointer;
    font-size: 26px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  .z-index99 {
    z-index: 99999;
  }

  .sound-ui {
    .sound-slice {
      background-color: #fff;
      transition: all 0.3s ease-in-out;
      left: 66px;
      transform: translate(0, 0);
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      top: -30px;
      position: absolute;
      width: 230px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 21.38px, rgba(0, 0, 0, 0.19) 0px 32px 64px;
      padding: 5px 0;
      height: 78px;
      z-index: 100;
      @media (max-width: 768px) {
        top: 55px;
        left: 50%;
        transform: translate(-50%, 0);
        height: 88px;
      }
      &::before {
        content: '';
        position: absolute;
        bottom: 10px;
        left: -10px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #fff;
        @media (max-width: 768px) {
          top: -10px;
          left: 60%;
          transform: rotate(90deg) translate(-50%, 0);
        }
      }
      &.audio-null {
        top: -0px;
        height: auto !important;
      }
      &.loading-mock {
        height: 69px;
      }
      .sound-flex {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .empty-data {
          margin-left: -50px;
          color: var(--quaternary-color);
        }
      }
      .sound-close-btn {
        position: absolute;
        border-radius: 8px;
        padding: 0 5px 5px 5px;
        margin: -3px 5px 5px 5px;
        right: 0;
        font-size: 16px;
      }
      @media (max-width: 768px) {
        right: 66px;
        left: unset;
        .sound-flex {
          flex-direction: row-reverse;
        }
      }
    }
    .close-sound-ui {
      opacity: 0;
    }
    .open-sound-ui {
      opacity: 1;
    }
  }
  .ring-wrapper {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 30px;
    position: relative;

    @media (max-width: 990px) {
      max-width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 690px) {
      width: 80px;
    }
  }
  .ring-group {
    position: absolute;
    left: 55%;
    top: 50%;
    transform: translate(-55%, -50%);
    label {
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
  .lds-ring {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -60px;
    position: relative;
    @media (max-width: 768px) {
      left: -50px;
    }
    @media (max-width: 690px) {
      display: none;
    }
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 2px;
    left: 50%;
    width: 15px;
    height: 15px;
    margin: auto;
    border: 2px solid var(--quaternary-color);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--quaternary-color) transparent transparent transparent;
    @media (max-width: 768px) {
      /* right: 65%; */
      left: unset;
      .sound-flex {
        flex-direction: row-reverse;
      }
    }
  }
  .lds-ring div:nth-of-type(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-of-type(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-of-type(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export default NewWidgetSpeech;
