import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import parse from 'html-react-parser';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { AiOutlineClockCircle, AiOutlineEye } from 'react-icons/ai';
import StickyBox from 'react-sticky-box';

import SEO from 'next-seo.config';
import PageError from 'pages/404';
import CardH from 'src/components/cards/card-h';
import Layout from 'src/components/layouts';
import {
  APP_IMG,
  APP_URL,
  CLIENT_GSERVICE_URL,
  NEXT_PUBLIC_CLOSE_TRANSLATE,
  NEXT_PUBLIC_DEFAULT_COUNT_VIEW,
  NEXT_PUBLIC_SPEED_KEYPOINT,
  NEXT_PUBLIC_URL_SOUND,
  NEXT_PUBLIC_WEB_NAME_SOUND,
  SHOW_IMAGE_DESC_ID
} from 'src/constants';
import { IMAGE_DEFAULT, STATIC_FILE, WEB_DESC, WEB_KEYWORD, WEB_NAME, WEB_TITLE, WEB_URL } from 'src/constants/layout';
import { arrayEmpty, convertObjPath, escapeChar, htmlToPlainText, rewriteTxt } from 'src/utils/helper';

import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import Carousels from 'src/components/carousels';
import SectionAffiliate from 'src/components/sections/section-event/section-affiliate';
import WidgetStickyContents from 'src/components/widgets/widget-sticky-contents';

import Script from 'next/script';
import { decryptionClient } from 'src/services/member-auth/utils/decryption-payload';
import { KEY_ADS_POSITION } from 'src/utils/ads/dynamic-ads';
import { getAffiliate } from 'src/utils/contents/get-affiliate';
import { getFeatureImage } from 'src/utils/contents/get-image';
import { setAds } from 'src/utils/contents/set-ads';
import { setContents } from 'src/utils/contents/set-contents';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { useDesktop } from 'src/utils/device';
import { GET, GET_ARTICLES } from 'src/utils/services';
import useUser from 'src/utils/useUser';
import styled from 'styled-components';

const AdsNative = dynamic(import('src/components/ads/ads-native'), { ssr: false });
const DynamicAds = dynamic(import('src/utils/ads/dynamic-ads'), { ssr: true });
const PageCategoryDetail = props => {
  const { article, related, lastNews, isTranslated, dataAffiliate, dataAdsNative, ads, dataAdsHeader } = props;
  const _data = useUser();
  const sessionSevId = _data?.user ? decryptionClient(_data?.user) : null;
  if (!article) {
    return <PageError dataAdsHeader={dataAdsHeader} sessionSevId={sessionSevId} />;
  }
  const [isTranslate, setIsTranslate] = useState(false);
  const [isKeyPoint, setIsKeyPoint] = useState(true);
  const [selectLang, setSelectLang] = useState('TH');
  const [newsArticle, setNewsArticle] = useState({ ...article });
  const router = useRouter();
  const isDesktop = useDesktop();
  const [updateCountView, setUpdateCountView] = useState(0);
  const [adsBox, setAdsBox] = useState(true);
  const handleLightBoxClick = () => {
    setAdsBox(false);
  };

  const _article = {
    ...article,
    ...newsArticle,
    title: article?.id,
    title: newsArticle?.meta?.title,
    description: newsArticle?.meta?.description || WEB_DESC,
    keyword: newsArticle?.meta?.keyword || WEB_KEYWORD,
    id: newsArticle?.id,
    link: newsArticle?.link,
    tags: newsArticle?.tags,
    section: newsArticle?.section,
    image_url: newsArticle?.meta?.og_social_image,
    subCategory: newsArticle?.subCategory
  };
  const baseUrlCategory = `${_article?.canonical}`;
  const dateUpdate = isTranslate ? dayjs(_article?.published_at).format('DD MMM YYYY - H:mm') : dayjs(_article?.published_at).locale('th').format('DD MMM YYYY เวลา H:mm น.');

  const countViewUpdate = _article?.pageviews + updateCountView;

  const articleTitle = escapeChar(_article?.title);
  const articleDescription = escapeChar(_article?.description);
  const categoryName = _article?.subCategory?.[isTranslate ? 'en' : 'th'] ? _article?.subCategory?.[isTranslate ? 'en' : 'th'] : _article?.section?.[isTranslate ? 'en' : 'th'];
  const categoryPath = _article?.subCategory?.en ? `${_article?.section?.en}/${_article?.subCategory?.en}` : _article?.section?.en;

  /* -------------------------------- DATALAYER ------------------------------- */

  // const nextSectionIndexRef = useRef(0);
  // const sectionsVisibleRef = useRef([]);
  // const sectionRef = useRef(null);
  // const footerRef = useRef(false);

  const [isLoading, setIsLoading] = useState(false);
  const handleTranslate = async newLanguage => {
    try {
      setSelectLang(newLanguage);
      setIsLoading(true);
      setIsTranslate(!isTranslate);
      setIsKeyPoint(false);
      if (NEXT_PUBLIC_URL_SOUND !== undefined || !_.isEmpty(_article?.id)) {
        const uriToken = `${NEXT_PUBLIC_URL_SOUND}/create`;
        const getToken = await axios
          .post(
            uriToken,
            {
              app: 'default'
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'User-Agents': `NationSpeech/1.0 ${props?.agent}`
              }
            }
          )
          .catch(error => {
            console.error(error);
          });

        // console.log({ getToken });
        if (getToken?.data?.data?.token !== undefined) {
          const uriEn = `${NEXT_PUBLIC_URL_SOUND}/translates/${_article?.id}/${newLanguage}`;
          // console.log('uriEn', uriEn);

          const translate = await axios
            .post(
              uriEn,
              {
                data: {
                  id: _article?.id,
                  site: NEXT_PUBLIC_WEB_NAME_SOUND,
                  language: newLanguage
                }
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'User-Agents': `NationSpeech/1.0 ${props?.agent}`,
                  accessToken: getToken?.data?.data?.token,
                  accessTimeStamp: getToken?.data?.data?.timestamp
                }
              }
            )
            .catch(error => {
              console.error(error);
            });
          await new Promise(resolve => setTimeout(resolve, 500));
          setNewsArticle({
            ...article,
            ...translate?.data?.data
          });
        }
      }
    } catch (error) {
      setIsLoading(true);
      console.log(error, 'Error Translate ');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    try {
      localStorage.setItem('_t', '0');
      const increment = () => {
        const count = localStorage.getItem('_t');
        const time = parseInt(count);
        localStorage.setItem('_t', time + 1);
      };
      setInterval(increment, 1000);
    } catch (err) {
      // PROVIDE FEEDBACK TO THE USER
      console.log('Error: Please enable website cookies. ' + err);
    }
  }, []);

  useEffect(() => {
    // window.scrollTo(0, 0);
    // ---------------------------------- FIX STYLE ----------------------------------
    Number(_article?.id) > Number(SHOW_IMAGE_DESC_ID) && setContents();
    setAds();
    // -------------------------------- GET COUNTVIEW --------------------------------
    (async () => {
      const params = {
        site: 'bangkokbiznews',
        newsid: _article.id
      };
      try {
        const res = await fetch(`${CLIENT_GSERVICE_URL}/cpvs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params)
        });
        const resJson = await res.json();
        setUpdateCountView(resJson);
      } catch (err) {
        setUpdateCountView(0);
      }
    })();
  }, []);
  // handleCommemorate
  function getScrollTop() {
    if (typeof window.pageYOffset !== 'undefined') {
      // Most browsers
      return window.pageYOffset;
    }

    const d = document.documentElement;
    if (typeof d.clientHeight !== 'undefined') {
      // IE in standards mode
      return d.scrollTop;
    }

    // IE in quirks mode
    return document.body.scrollTop;
  }
  const isMb = useMediaQuery({ query: '(max-width: 768px)' });
  useEffect(() => {
    if (isMb) {
      window.onscroll = function () {
        const boxJs = document.getElementById('widget-group-js');
        const box = document.getElementById('widget-group');
        if (!_.isEmpty(box || boxJs)) {
          const scroll = getScrollTop();
          if (scroll <= 20) {
            if (!_.isEmpty(box)) box.style.top = '112px';
            if (!_.isEmpty(boxJs)) boxJs.style.top = '112px';
          } else {
            if (!_.isEmpty(box)) box.style.top = 70 + 'px';
            if (!_.isEmpty(boxJs)) boxJs.style.top = 70 + 'px';
          }
        }
      };
    }
  }, []);

  useEffect(() => {
    var keypointContent = document.getElementById('keypoint-content');
    while (keypointContent?.firstChild) {
      keypointContent?.removeChild(keypointContent?.firstChild);
    }

    if (isKeyPoint === true && !_.isEmpty(_article?.key_point)) {
      const element = document.getElementById('feature-image');
      element?.scrollIntoView({ behavior: 'smooth', top: 0 });

      var div = document.createElement('div');
      div.innerHTML = _article?.key_point || '';

      var strongElements = div.getElementsByTagName('strong');
      for (let i = 0; i < strongElements.length; i++) {
        strongElements[i].style.fontWeight = 'bold';
      }

      var speed = NEXT_PUBLIC_SPEED_KEYPOINT ?? 50;

      var txt = _article?.key_point;
      var i = 0;
      var div;

      txt = htmlToPlainText(txt?.replace(/<\/?[^>]+(>|$)/g, match => (match === '<li>' ? '_' : '')));

      let ul = document.createElement('ul');
      let li = document.createElement('div');

      let intervalId = setInterval(() => {
        if (i < txt.length) {
          let char = txt.charAt(i);
          if (char === '_') {
            keypointContent.appendChild(ul);
            li = document.createElement('li');
            // Create a new li element for the next line
            keypointContent.appendChild(li);
            ul.appendChild(li);
            i++;
          } else {
            li.innerHTML += char;
            i++;
          }
        }
        // else {
        //     clearInterval(intervalId);
        // }
      }, speed);

      return () => clearInterval(intervalId);
    }
  }, [isKeyPoint]);

  return (
    <>
      {/* -------------------------------- NEXT_SEO -------------------------------- */}
      <NextSeo
        {...SEO}
        title={_article?.title}
        description={_article?.description}
        openGraph={{
          ...SEO.openGraph,
          title: _article?.title,
          description: _article?.description,
          url: baseUrlCategory,
          article: {
            publishedTime: _article?.published_at,
            modifiedTime: _article?.updated_at,
            expirationTime: null,
            section: _article?.section?.th,
            authors: [`${WEB_URL.FACEBOOK}`],
            tags: _article?.tags
          },
          images: [
            {
              url: _article?.image_url,
              width: '750',
              height: '422',
              alt: _article?.title
            }
          ]
        }}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: _article?.keyword
          }
        ]}
      />
      {/* --------------------------------- HEADER --------------------------------- */}
      <Head>
        <link rel='canonical' href={baseUrlCategory} />
        <meta property='twitter:title' content={_article?.title || WEB_TITLE} />
        <meta property='twitter:description' content={_article?.description || WEB_DESC} />
        <meta property='twitter:image' content={!_.isEmpty(_article?.image) ? `${APP_IMG}${_article?.image}` : IMAGE_DEFAULT} />
        {/* ----------------------------- ScriptBrowser ------------------------------------ */}
        {!useDesktop() && !_.isEmpty(dataAdsHeader) ? parse(`${dataAdsHeader?.header_mobile}`) : ''}
        {useDesktop() && !_.isEmpty(dataAdsHeader) ? parse(`${dataAdsHeader?.header_desktop}`) : ''}
        {/* ----------------------------- ADS : PPN ------------------------------- */}
        <script async type='text/javascript' src='https://securepubads.g.doubleclick.net/tag/js/gpt.js' />
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: 'var googletag = googletag || {}; window.googletag.cmd = googletag.cmd || [];'
          }}
        />
        {/* --------------------------------- Truehit -------------------------------- */}
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
                __th_page="${_article?.section?.en}";
              `
          }}
        />

        {/* ---------------------------- SPIPPET : WebSite --------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `
                {
                  "@context": "https://schema.org",
                  "@type": "WebSite",
                  "name": "${WEB_NAME}",
                  "url": "${WEB_URL.WEB}",
                  "description": "${articleDescription || WEB_DESC}",
                  "sameAs": [
                    "${WEB_URL.FACEBOOK}",
                    "${WEB_URL.TWITTER}",
                    "${WEB_URL.INSTAGRAM}",
                    "${WEB_URL.YOUTUBE}",
                    "${WEB_URL.LINE}"
                  ],
                  "potentialAction": {
                    "@type": "SearchAction",
                    "target": "${WEB_URL.WEB}search?q={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                }
              `
          }}
        />

        {/* ---------------------------- SPIPPET : WebPage --------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: ` 
                { 
                  "@context": "http://schema.org", 
                  "@type": "WebPage", 
                  "name": "${articleTitle}", 
                  "description": "${articleDescription}", 
                  "publisher": { 
                    "@type": "ProfilePage", 
                    "name": "${WEB_NAME}" 
                  } 
                } 
              `
          }}
        />

        {/* ------------------------ SPIPPET : BreadcrumbList ------------------------ */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: ` 
                { 
                  "@context": "http://schema.org", 
                  "@type": "BreadcrumbList", 
                  "itemListElement": [ 
                    { 
                      "@type": "ListItem", 
                      "position": 1, 
                      "name": "หน้าแรก", 
                      "item": "${APP_URL}" 
                    }, 
                    { 
                      "@type": "ListItem", 
                      "position": 2, 
                      "name": "${_article?.section?.th}", 
                      "item": "${APP_URL}/${_article?.section?.en}" 
                    }, 
                    { 
                      "@type": "ListItem", 
                      "position": 3, 
                      "name": "${articleTitle}", 
                      "item": "${_article?.canonical || (!_.isEmpty(_article?.link) && `${APP_URL}${_article?.link}`) || `${APP_URL}/${_article?.section?.en}/${_article?.id}`}" 
                    } 
                  ] 
                } 
              `
          }}
        />

        {/* -------------------------- SPIPPET : NewsArticle ------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: ` 
                { 
                  "@context": "http://schema.org", 
                  "@type": "NewsArticle", 
                  "mainEntityOfPage": { 
                    "@type": "WebPage", 
                    "@id": "${_article?.canonical || (!_.isEmpty(_article?.link) && `${APP_URL}${_article?.link}`) || `${APP_URL}/${_article?.section?.en}/${_article?.id}`}" 
                  }, 
                  "headline": "${articleTitle}", 
                  "alternativeHeadline": "${articleTitle}", 
                  "description": "${articleDescription}", 
                  "image": "${APP_IMG}${_article?.image}", 
                  "datePublished": "${_article?.published_at}",
                  "dateModified": "${_article?.updated_at}",
                  "author": { 
                    "@type": "Thing", 
                    "name": "${WEB_NAME}",
                    "url": "${APP_URL}"
                  }, 
                  "publisher": { 
                    "@type": "Organization", 
                    "name": "${WEB_NAME}", 
                    "logo": { 
                      "@type": "ImageObject", 
                      "url": "${APP_IMG}/static/${STATIC_FILE.ICON_LOGO}" 
                    } 
                  } 
                } 
              `
          }}
        />

        {/* --------------------------- Social embed script -------------------------- */}
        <script async src='//www.instagram.com/embed.js' />
        {/* <script async src='https://platform.twitter.com/widgets.js' /> */}
        {/* <img
              width='99999'
              height='99999'
              alt='background-default'
              title='background-default'
              className='background-default'
              style={{
                pointerEvents: 'none',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '99vw',
                height: '99vh',
                maxWidth: '99vw',
                maxHeight: '99vh'
              }}
              src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI5OTk5OXB4IiBoZWlnaHQ9Ijk5OTk5cHgiIHZpZXdCb3g9IjAgMCA5OTk5OSA5OTk5OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZyBzdHJva2U9Im5vbmUiIGZpbGw9Im5vbmUiIGZpbGwtb3BhY2l0eT0iMCI+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9Ijk5OTk5IiBoZWlnaHQ9Ijk5OTk5Ij48L3JlY3Q+IDwvZz4gPC9zdmc+'
            ></img> */}
      </Head>
      {!_.isEmpty(dataAffiliate) && <SectionAffiliate position={4} data={dataAffiliate} />}
      <Script id='script-creden' strategy='afterInteractive' defer src='https://data.creden.co/js/detect_company_name.js?client_name=bangkokbiznews' />
      <Layout
        classLayout='layout-content'
        ads={ads}
        sessionSevId={sessionSevId}
      >

        {/* ---------------------------- ADS : LIGHTBOX ----------------------------- */}
        {_article?.is_show_ads && !_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.LIGHTBOX} data={ads} handler={() => handleLightBoxClick()} adsBox={adsBox} />}

        {/* ---------------------------- ADS : Content ----------------------------- */}
        {_article?.is_show_ads && !_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} data={ads} />}

        <div className='detail-wrapper'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-1 col-md-12'>
                {/* // handleCommemorate */}
                {/* <div
                  id={`${NEXT_PUBLIC_IS_COMMEMORATE === 'true' ? 'widget-group-js' : 'widget-group'}`}
                  className={`${NEXT_PUBLIC_IS_COMMEMORATE === 'true' ? 'position-sticky-left-content-js' : 'position-sticky-left-content'} back-drop  ${scrolled ? 'scroll-active' : ''}`}
                >
                  <WidgetSpeech
                    _id={_article.id}
                    _data={_article}
                    //! DATALAYER
                    eventDataLayer={{
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      section: 'ฟังข่าว',
                      position: 'ด้านซ้าย:content',
                      data: {
                        heading: 'ฟังข่าว'
                      }
                    }}
                  />
                  {!router.asPath.includes('/royal/') && <WidgetTranslate
                    language={isTranslate ? 'TH' : 'EN'}
                    isLoading={isLoading}
                    isTranslate={isTranslate}
                    handleTranslate={handleTranslate}
                    //! DATALAYER
                    eventDataLayer={{
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      section: 'แปลภาษา',
                      position: 'ด้านซ้าย:content',
                      data: {
                        heading: 'แปลภาษา',
                        title: _data.title,
                        index: 1
                      }
                    }} />}
                  <WidgetShareSticky
                    _data={_article}
                    link={_article.link}
                    //! DATALAYER
                    eventDataLayer={{
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      section: 'แชร์ข่าว',
                      position: 'ด้านซ้าย:content',
                      data: {
                        heading: 'แชร์ข่าว'
                      }
                    }}
                  />
                   {_article?.key_point &&
                    <WidgetKeyPoint
                      isShow={isKeyPoint}
                      data={_article?.key_point}
                      handleKeyPoint={() => {
                        setIsKeyPoint(!isKeyPoint);
                    
                        if (!isKeyPoint) {
                          const element = document.getElementById('key-point');
                          element.scrollIntoView({ behavior: 'smooth', top: 10 });
                        }
                        sendDataLayerGlobal({
                          type: DATALAYER_TYPE.TRACK_POSITION,
                          router: router?.pathname,
                          section: 'keypoint',
                          position: 'ด้านซ้าย:content',
                          data: {
                            heading: 'keypoint',
                            title: _data.title,
                            index: 1
                          }
                        });
                      }}
                    />
                    }
                </div> */}
                 {/* {_article?.key_point &&
                    <WidgetKeyPoint
                      isShow={true}
                      data={_article?.key_point}
                      handleKeyPoint={() => {
                        setIsKeyPoint(!isKeyPoint);
                    
                        if (!isKeyPoint) {
                          const element = document.getElementById('key-point');
                          element.scrollIntoView({ behavior: 'smooth', top: 10 });
                        }
                        sendDataLayerGlobal({
                          type: DATALAYER_TYPE.TRACK_POSITION,
                          router: router?.pathname,
                          section: 'keypoint',
                          position: 'ด้านซ้าย:content',
                          data: {
                            heading: 'keypoint',
                            title: _data.title,
                            index: 1
                          }
                        });
                      }}
                    />
                    } */}
              </div>
              {/* ----------------------------------- Main : Start ---------------------------------- */}
              <SectionWrapper id='section-1' className='col-lg-7 col-md-12'>
                <ul className='breadcrumb'>
                  <li>
                    <a href='/'>{isTranslate ? 'Home' : 'หน้าแรก'}</a>
                  </li>
                  <li> {categoryName}</li>
                </ul>
                <div id='contents' className='main-content'>
                  {/* ----------------------------------- Heading : Start ---------------------------------- */}
                  <div className='content-heading-wrapper'>
                    {_article?.section?.th !== null && (
                      <a className='content-category' href={`/category/${categoryPath}`}>
                        {categoryName}
                      </a>
                    )}
                    <h1 className='content-title'>{newsArticle?.title}</h1>
                    <div className='content-meta'>
                      {_article?.byline !== null && (
                        <span>
                          By <a>{_article?.byline}</a>
                        </span>
                      )}
                      <DateWrapper className='date' showCountView={countViewUpdate > Number(NEXT_PUBLIC_DEFAULT_COUNT_VIEW)}>
                        <AiOutlineClockCircle size={14} />
                        {dateUpdate}
                      </DateWrapper>
                      <span className='views'>
                        {countViewUpdate > 0 && countViewUpdate > Number(NEXT_PUBLIC_DEFAULT_COUNT_VIEW) && (
                          <>
                            <AiOutlineEye size={14} />
                            {countViewUpdate > 999 ? numeral(countViewUpdate).format('0.0a') : countViewUpdate}
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                  {/* ----------------------------------- Heading : End ---------------------------------- */}

                  {/* ----------------------------------- Feature Image : Start  ---------------------------------- */}
                  <div id='feature-image' className='content-feature-image'>
                     {/* <img className='before' src={`${APP_IMG}/images/border-card.png`} /> */}
                    {getFeatureImage(_article)}
                  </div>
                 {isTranslated === true && <WidgetStickyContents className='block-sticky' isLoading={isLoading} article={article} handleTranslate={handleTranslate} selectLang={selectLang} router={router} isTranslated={isTranslated} />}
                  {/* ----------------------------------- Feature Image : End  ---------------------------------- */}
                  {/* ----------------------------------- Content : Start ---------------------------------- */}
                  <div id='content' className='content-wrapper'>
                    <ArticleWrapper>
                       
                      {
                        _.isEmpty(_article.key_point) &&
                        _article?.blurb !== '' && (
                          <h2 className='content-blurb'>
                            <blockquote dangerouslySetInnerHTML={{ __html: _article?.blurb }} />
                          </h2>
                        )
                      }
                       {!_.isEmpty(_article.key_point) && (
                        <div className='keypoint-flex'>
                          <div id='key-point' className={`keypoint-wrapper ${isKeyPoint ? 'dis' : 'hid'}`}>
                            <HeadSeo
                              dangerouslySetInnerHTML={{
                                __html: `${_article?.blurb || ''}`
                              }}
                            />

                            <div className='keypoint-header'>
                              <p>KEY</p>
                              <p>POINTS</p>
                            </div>
                            <div
                              id='keypoint-content'
                              className='keypoint-lists'
                              dangerouslySetInnerHTML={{
                                __html: _article.key_point ?? ''
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {/* {!_.isEmpty(_article.key_point) ? (
                       <div className='keypoint-wrapper'>
                          <HeadSeo
                            dangerouslySetInnerHTML={{
                              __html: `${_article?.blurb || ''}`
                            }}
                          />
                          <div className='keypoint-header'>
                            <p>KEY</p>
                            <p>POINTS</p>
                          </div>
                          <div
                            className='keypoint-lists'
                            dangerouslySetInnerHTML={{
                              __html: _article.key_point ?? ''
                            }}
                          />
                        </div>
                      ) : (
                        _article?.blurb !== '' && (
                          <h2 className='content-blurb'>
                            <blockquote dangerouslySetInnerHTML={{ __html: _article?.blurb }} />
                          </h2>
                        )
                      )} */}
                    </ArticleWrapper>
                    <div id='paragraph' className='detail'>

                      {/* ---------------------------- ADS : INARTICLE-1 ----------------------------- */}
                      {_article?.content_I && (
                        <>
                          {_article?.is_show_ads && !_.isEmpty(ads) && (
                            <DynamicAds position={KEY_ADS_POSITION.INARTICLE_1} data={ads} />
                          )}        
                                 
                          {_article?.is_creden ? (
                            <div
                              id='paragraph-1'
                              data-news-content='company'
                              className='content-detail'
                              dangerouslySetInnerHTML={{
                                __html: _article?.content_I
                              }}
                            />
                          ) : (
                            <div
                              id='paragraph-1'
                              className='content-detail'
                              dangerouslySetInnerHTML={{
                                __html: _article?.content_I
                              }}
                            />
                          )}
                          {_article?.is_show_ads && getAffiliate(1, dataAffiliate)}
                        </>
                      )}

                      {_article?.content_II && (
                        <>
                          {/* ---------------------------- ADS : INARTICLE-2 ----------------------------- */}
                          {_article?.is_show_ads && !_.isEmpty(ads) && (
                            <DynamicAds position={KEY_ADS_POSITION.INARTICLE_2} data={ads} />
                          )}

                          {_article?.is_creden ? (
                            <div
                              id='paragraph-2'
                              data-news-content='company'
                              className='content-detail'
                              dangerouslySetInnerHTML={{
                                __html: _article?.content_II
                              }}
                            />
                          ) : (
                            <div
                              id='paragraph-2'
                              className='content-detail'
                              dangerouslySetInnerHTML={{
                                __html: _article?.content_II
                              }}
                            />
                          )}
                          {_article?.is_show_ads && getAffiliate(2, dataAffiliate)}
                        </>
                      )}
                      {/* ---------------------------- relate news: desktop -------------------------- */}
                      {!_.isEmpty(related) && (
                        <div className='widget-content related-news-desktop'>
                          <div className='widget-content-title'>
                            <h3 className='related-news'>เนื้อหาที่เกี่ยวข้อง</h3>
                          </div>
                          <div className='block-content'>
                            <ul className='block-list'>
                              {related.slice(0, 3).map((item, index) => (
                                <li key={index}>
                                  <CardH
                                    data={item}
                                    large={false}
                                    external={false}
                                    //! DATALAYER
                                    eventDataLayer={{
                                      type: DATALAYER_TYPE.TRACK_POSITION,
                                      router: router?.pathname,
                                      section: 'เนื้อหาที่เกี่ยวข้อง',
                                      position: 'ใต้:paragraph-2',
                                      data: {
                                        block: '1',
                                        title: item?.title,
                                        heading: 'เนื้อหาที่เกี่ยวข้อง',
                                        index: index + 1
                                      }
                                    }}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                      {_article?.content_III && (
                        <>
                          {/* ---------------------------- ADS : INARTICLE-3 ----------------------------- */}
                          {_article?.is_show_ads && !_.isEmpty(ads) && (
                            <DynamicAds position={KEY_ADS_POSITION.INARTICLE_3} data={ads} />
                          )}

                          {_article?.is_creden ? (
                            <div
                              id='paragraph-3'
                              data-news-content='company'
                              className='content-detail'
                              dangerouslySetInnerHTML={{
                                __html: _article?.content_III
                              }}
                            />
                          ) : (
                            <div
                              id='paragraph-3'
                              className='content-detail'
                              dangerouslySetInnerHTML={{
                                __html: _article?.content_III
                              }}
                            />
                          )}
                          {_article?.is_show_ads && getAffiliate(3, dataAffiliate)}
                        </>
                      )}

                      {/* ---------------------------- ADS POSTARTICE ---------------------------- */}
                      {_article?.is_show_ads && !_.isEmpty(ads) && (
                        <div id='postartice' className='ads-sticky-overflow'>
                          <DynamicAds position={KEY_ADS_POSITION.POSTARTICLE} data={ads} />
                        </div>
                      )}
                    </div>

                    {/*  --------------------------------- Related Mobile --------------------------------  */}

                    <div className='carousels-related'>
                      {/* related mobile */}
                      {/* {!_.isEmpty(related) && (
                        <div className='related-scroll widget-content'>
                          <div className='block-content'>
                            {
                              <ul className='block-list'>
                                {related.map((item, index) => (
                                  <li key={index}>
                                    <CardH
                                      data={item}
                                      large={false}
                                      //! DATALAYER
                                      eventDataLayer={{
                                        type: DATALAYER_TYPE.TRACK_POSITION,
                                        router: router?.pathname,
                                        section: 'ข่าวที่เกี่ยวข้อง',
                                        position: 'ใต้:เนื้อหาที่-3',
                                        data: {
                                          block: '1',
                                          title: item?.title,
                                          heading: 'ข่าวที่เกี่ยวข้อง',
                                          index: index + 1
                                        }
                                      }}
                                    />
                                  </li>
                                ))}
                              </ul>
                            }
                          </div>
                        </div>
                      )} */}

                      {_article?.tags?.length > 0 && (
                        <div id='read-complete' className='tags-wrapper'>
                          <div className='tags-item'>
                            <span>แท็กที่เกี่ยวข้อง</span>
                            {_article?.tags?.map((item, index) => (
                              <a
                                key={index}
                                href={`/tags/${item}`}
                                //! DATALAYER
                                onClick={() =>
                                  sendDataLayerGlobal({
                                    type: DATALAYER_TYPE.TRACK_POSITION,
                                    router: router?.pathname,
                                    section: 'แท็กที่เกี่ยวข้อง',
                                    position: isDesktop ? 'ใต้:เนื้อหาที่-3' : 'ใต้:ข่าวที่เกี่ยวข้อง',
                                    data: {
                                      block: '1',
                                      title: item,
                                      heading: 'แท็กที่เกี่ยวข้อง',
                                      index: index + 1
                                    }
                                  })
                                }
                              >
                                {item}
                              </a>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <Carousels
                      // heading='ข่าวที่น่าสนใจ'
                      showDots={true}
                      autoPlay={true}
                      responsiveShow={1}
                    >
                      {!arrayEmpty(lastNews) &&
                        lastNews.map((item, index) => (
                          <div key={index} className='card-carousel'>
                            <CardH
                              withTarget={true}
                              external={false}
                              data={item}
                              path={item.link}
                              //! DATALAYER
                              eventDataLayer={{
                                type: DATALAYER_TYPE.TRACK_POSITION,
                                router: router?.pathname,
                                section: 'ข่าวล่าสุด',
                                position: 'ใต้:แชร์ข่าว',
                                data: {
                                  block: '1',
                                  title: item?.title,
                                  heading: 'ข่าวล่าสุด',
                                  carousel: index + 1
                                }
                              }}
                            />
                          </div>
                        ))}
                    </Carousels>

                    {/* ------------ Ads : infinities -------------- */}
                    {_article?.is_show_ads && <AdsNative data={dataAdsNative} isShowAds={_article?.is_show_ads} />}
                  </div>

                  {/* ----------------------------------- Side : Start ---------------------------------- */}
                </div>
              </SectionWrapper>
              <div className='col-lg-4 col-md-12 d-lg-block d-none'>
                <StickyBox offsetTop={70} offsetBottom={70}>
                  {_article?.is_show_ads && !_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.STICKY_1} data={ads} />}

                  <div className='widget-content'>
                    <div className='widget-content-title'>
                      <h3>ข่าวล่าสุด</h3>
                    </div>
                    <div className='block-content'>
                      {Array.isArray(lastNews) && lastNews.length < 1 ? (
                        <div className='no-article'>
                          <img className='--side' src={`${APP_IMG}/no-article.png`} alt='no-article' width='100%' />
                        </div>
                      ) : (
                        <ul className='block-list'>
                          {lastNews.slice(0, 4).map((item, index) => (
                            <li key={index}>
                              <CardH
                                data={item}
                                large={false}
                                //! DATALAYER
                                eventDataLayer={{
                                  type: DATALAYER_TYPE.TRACK_POSITION,
                                  router: router?.pathname,
                                  section: 'ข่าวล่าสุด',
                                  position: 'ใต้:แชร์ข่าว',
                                  data: {
                                    block: '2',
                                    title: item?.title,
                                    heading: 'ข่าวล่าสุด',
                                    index: index + 1
                                  }
                                }}
                              />
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>

                  {/* ---------------------------- ADS : STICKY-2 ----------------------------- */}
                  {article?.is_show_ads && !_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.STICKY_2} data={ads} />}
                </StickyBox>
              </div>
              {/* ----------------------------------- Side : End ---------------------------------- */}
            </div>
          </div>
        </div>
        {_article?.is_show_ads && !_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.AD1X1} data={ads} />}
      </Layout>
    </>
  );
};
const SectionWrapper = styled.div`
  .empty-bg {
    background: unset !important;
  }
  /* .content-feature-image {
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      padding-top: 56.25%;
      z-index: 1;
      background-image: url(https://image.bangkokbiznews.com/images/border-card.png);
      background-size: cover;
      background-repeat: repeat;
    }
    @media (max-width: 475px) {
      &:before , img {

        border-radius: 8px;
      }
    }
  } */
`;
const DateWrapper = styled.span`
  border-right: ${props => (props?.showCountView ? '1px solid #dedede' : 'unset')} !important;
`;
const HeadSeo = styled.h2`
  position: absolute;
  visibility: hidden;
  overflow: hidden;
`;

const ArticleWrapper = styled.div`
  .ul-auto {
    list-style-type: circle;
  }
  .li-auto::marker {
    font-size: 24px;
    line-height: 0;
    color: #0600E7;
  }
  .keypoint-wrapper {
    gap: 25px;
    display: flex;
    /* border-top: 7px solid #0600E7; */
    padding: 30px 0;
    margin-top: 10px;
    border-bottom: 1px dashed #0600E7;

    .keypoint-header {
      margin-top: 8px;
      p {
        color: #0600E7;
        font-size: 25px;
        font-weight: bolder;
        letter-spacing: 0.4px;
        line-height: 1.05;
        text-transform: uppercase;
      }
    }
    .keypoint-lists::after {
      content: attr(data-text);
      white-space: pre-wrap;
      overflow: hidden;
      max-width: 100%;
      animation: typewriter 4s steps(50) 1s forwards;
    }

    /* .keypoint-lists {
      position: relative;
      display: block;
      width: 100%;
      letter-spacing: 0.1;
      overflow: hidden;
      transition: all 0.4s ease 0s;

      max-height: 0;
      white-space: pre-line;
      opacity: 1;
      animation: typewriter 6s steps(100) 0.4s normal both;
        }
        */
    &.hid {
      display: none;
      opacity: 0;
      animation: typewriterReverse 6s steps(100) 0.4s normal both;
    }
    @keyframes typewriter {
      0% {
        max-height: 0;
      }
      20% {
        max-height: 50em;
      }
      100% {
        max-height: 100em;
      }
    }
    @keyframes typewriterReverse {
      100% {
        max-height: 0;
      }
      20% {
        max-height: 50em;
      }
      0% {
        max-height: 100em;
      }
    }

    ul {
      padding-left: 30px;
      line-height: 16px;

      @media (max-width: 767px) {
        padding-left: 15px;
        padding-top: 15px;
      }
      li {
        display: flex;
        align-items: flex-start;

        ::marker {
          font-size: 24px;
          line-height: 0;
          color: #0600E7;
        }
        list-style-type: disc;
        display: list-item;
        margin-left: 1.5rem;
        line-height: 1.65;
        font-size: 20px;
        a {
          letter-spacing: 0.2px;
          font-display: swap;
        }
      }
    }
    @media (max-width: 767px) {
      display: block;

      padding: 20px 0;
      margin-bottom: 30px;
      .keypoint-header {
        display: flex;
        p {
          font-size: 22px;
        }
        margin-top: 0px;
      }
      ul {
        li {
          font-size: 16px;
        }
      }
    }
  }
`;
export async function getServerSideProps({ req, res, params, query }) {
  const initProps = {
    statusCode: 200,
    errMessage: null,
    isTranslated: false,
    agent: null,
    related: null,
    lastNews: null,
    solrRelated: [],
    specialNews: false,
    dataAffiliate: [],
    dataAdsNative: null,
    ads: [],
    dataAdsHeader: null,
    article: null
  };

  // console.log(query);

  const { expires, signature } = query;

  const scriptBrowser = await GET('/api/headerAds');
  if (!_.isEmpty(scriptBrowser)) {
    initProps.dataAdsHeader = scriptBrowser || '';
  }
  let preview = '';
  let previewParams = {};
  if (expires && signature) {
    preview = '/preview';
    previewParams = {
      expires,
      signature
    };
  }

  const url = req.url;
  let uri = `contents/${rewriteTxt(params.id)}`;
  if (url.startsWith('/advertorials')) {
    uri = query?.type ? `contents/${params.id}/${query?.type || ''}` : `contents/${params.id}`;
  } else if (url.startsWith('/recommended')) {
    uri = query?.type ? `contents/${params.id}/${query?.type || ''}` : `contents/${params.id}`;
  } else if (url.startsWith('/pr-news')) {
    uri = query?.type ? `contents/${params.id}/${query?.type || ''}` : `contents/${params.id}`;
  } else if (url.startsWith('/pr')) {
    uri = query?.type ? `contents/${params.id}/${query?.type || ''}` : `contents/${params.id}`;
  } else if (url.startsWith('/blogs')) {
    uri = query?.type ? `contents/${params.id}/${query?.type || ''}` : `contents/${params.id}`;
  } else if (url.startsWith('/blog')) {
    uri = query?.type ? `contents/${params.id}/${query?.type || ''}` : `contents/${params.id}`;
  }

  if (rewriteTxt(params.id) === 'latest-news') {
    return {
      redirect: {
        destination: `${APP_URL}/category/latest-news`,
        permanent: false
      }
    };
  }
  try {
    /* -------------------------------------------------------------------------- */
    // console.log('data', uri, `/api/v1.0/${uri}${preview}${convertObjPath({ ...previewParams })}`);
    const resData = await GET_ARTICLES(`/api/v1.0/${uri}${preview}${convertObjPath({ ...previewParams })}`, false, true);

    if (resData?.statusCode === 404 || _.isUndefined(resData)) {
      return {
        redirect: {
          destination: '/',
          permanent: false
        }
      };
      // return {
      //   notFound: true
      // };
    }
   const envTranslate =  NEXT_PUBLIC_CLOSE_TRANSLATE?.slice(1, -1).split('/');
  //  console.log(NEXT_PUBLIC_CLOSE_TRANSLATE, envTranslate );

    // console.log(params.id, envTranslate.includes(params.id), envTranslate);
    
    // console.log(`/api/v1.0/${uri}${preview}${convertObjPath({ ...previewParams })}`, params.id);
    
    if (!_.isEmpty(resData?.data)) {
      initProps.article = resData?.data;
      initProps.agent = req.headers['user-agent'];
      initProps.related = resData?.related || [];
      initProps.solrRelated = resData?.solrRelated || [];
      initProps.lastNews = resData?.last_news || [];
      initProps.dataAffiliate = resData?.affiliate || [];
      initProps.ads = resData?.ads || [];
      initProps.dataAdsNative = resData?.adsNative || {};
      initProps.isTranslated = resData?.is_translate ? resData?.is_translate : (NEXT_PUBLIC_CLOSE_TRANSLATE ?  !envTranslate.includes(params.id) : true); // resData?.is_translate || {};
      initProps.agent = req.headers['user-agent'];
    }
  } catch (err) {
    // console.log('==========> ARTICLE DETAIL ERROR', err.message);
    initProps.errMessage = err.message;
    initProps.statusCode = err?.response?.status || 500;
  }

  return {
    props: initProps
  };
}

export default PageCategoryDetail;
