import styled from '@emotion/styled';
import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsFillShareFill, BsTwitterX } from 'react-icons/bs';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LineIcon, LineShareButton, TwitterShareButton } from 'react-share';
import { APP_URL } from 'src/constants';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import Image from '../image';

const WidgetShareSticky = ({ article, link, eventDataLayer }) => {
  const [isShare, setIsShare] = useState(false);

  const shareLink = `${APP_URL}${link}`;
  const SOCIAL_SHARE = [
    {
      id: 1,
      name: 'facebook',
      icon: (
        <FacebookShareButton url={shareLink}>
          <FacebookIcon size={36} round={true} />
        </FacebookShareButton>
      )
    },
    {
      id: 2,
      name: 'x',
      icon: (
        <TwitterShareButton url={shareLink}>
          <div className='x-sticky-wrapper'>
            <BsTwitterX />
          </div>
        </TwitterShareButton>

      )
    },
    {
      id: 3,
      name: 'line',
      icon: (
        <LineShareButton url={shareLink}>
          <LineIcon size={36} round={true} />
        </LineShareButton>
      )
    },
    {
      id: 4,
      name: 'email',
      icon: (
        <EmailShareButton url={shareLink}>
          <EmailIcon size={36} round={true} />
        </EmailShareButton>
      )
    }
  ];
  return (
    <WidgetWrapper className='widget-share'>
      <BsFillShareFill size={16} color='#0600E7' onClick={() => setIsShare(!isShare)} />
      {isShare && (
        <div className='share-popup'>
          <div className='share-detail'>
            <div className='share bar'>
              <label>แชร์</label>
              <AiOutlineClose color='#0600E7' className='close' onClick={() => setIsShare(!isShare)} />
            </div>
            <div className='share header'>
              <div className='share header-title'>
                <label className='share header-attribution'>กรุงเทพธุรกิจ</label>
                <label className='share header-abstract'>{article.title}</label>
              </div>
              <div className='share header-image'>
                <Image image={article?.image} title={article?.title} />
              </div>
            </div>
          </div>
          <div className='share grid'>
            {SOCIAL_SHARE.map((item, index) => (
              <div className='share item'>
                <div
                  key={index}
                  className={`--${item.name}`}
                  // ! DATALAYER
                  onClick={() =>
                    sendDataLayerGlobal({
                      ...eventDataLayer,
                      data: {
                        ...eventDataLayer.data,
                        title: item.name,
                        index: index + 1
                      }
                    })
                  }
                >
                  {item.icon}
                </div>
                <label>{item.name}</label>
              </div>
            ))}
          </div>
        </div>
      )}
    </WidgetWrapper>
  );
};

export default WidgetShareSticky;

const WidgetWrapper = styled.div`
  position: relative;
  text-align: center;
  background-color: #fff;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  background-color: rgba(31,41,154, 0.1);
  border-radius: 50%;
  padding: 10px;
  height: fit-content;
  svg {
    cursor: pointer;
    font-size: 26px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
  .disable-button {
    pointer-events: none;
  }
  .share-popup {
    // set style for popup
    position: absolute;
    background: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 21.38px, rgba(0, 0, 0, 0.19) 0px 32px 64px;
    opacity: 1;
    width: 352px;
    max-width: 352px;
    max-height: calc(100vh - 216px);
    min-height: 120px;
    top: -5px;
    left: auto;
    right: 45px;
    transition: all 0.4s ease 0s;
    @media (max-width: 768px) {
      top: 55px;
      left: -25px;
      right: 0;
      transform: translate(-80%, 0);
      width: 280px;
      max-width: 280px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 100%;
      right: 10px;
      width: 0;
      height: 0;
      transform: rotate(180deg);
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #fff;
      @media (max-width: 768px) {
        position: absolute;
        top: -10px;
        left: auto;
        right:8px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #fff;
        transform: rotate(90deg) translate(-50% , 0); 
      }
    }
    .close {
      position: absolute;
      top: 5px;
      right: 10px;
      padding: 5px;
      cursor: pointer;
      color: $main-color;
    }

    .share-detail {
      padding: 15px;
      border-bottom: 1px solid #b3b3b3;
      .share.bar {
        display: flex;
        justify-content: space-between;
        .close {
          @media (max-width: 768px) {
            font-size: 28px;
          }
        }
      }
      .share.header {
        display: flex;
        justify-content: space-evenly;
        margin-top: 4px;
        .share.header-title {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          margin-right: 12px;
        }
        .share.header-attribution {
          font-size: 12px;
          font-weight: 400;
          color: rgb(95, 95, 95);
          text-align: left;
        }
        .share.header-abstract {
          font-size: 14px;
          font-weight: 600;
          margin-top: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          text-align: left;
        }
        .share.header-image {
          flex-shrink: 0;
          img {
            /* img { */
            border-radius: 4px;
            width: 76px;
            height: 76px;
            object-fit: cover;
            /* } */
          }
        }
      }
    }
  }
  .share.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .share.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 96px;
    gap: 4px;
    .--X button {
      display: flex;
      align-items: start;
      height: 43px;
    }
  }
  .share.item label {
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
  }
  .x-sticky-wrapper {
    width: 36px;
    height: 36px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    svg {
      color: #fff;
      font-size: 18px;
    }
  }
`;
