import { APP_IMG } from 'src/constants';
import { arrayEmpty } from 'src/utils/helper';

export const getAffiliate = (_position, _data) => {
  if (arrayEmpty(_data) || !_data) return null;
  const item = _data.filter(_item => _item?.position === _position)[0];
  return (
    item && (
      <div className='content-detail text-center'>
        <a href={item?.link} title={item?.title} target='_blank' rel='noopener noreferrer nofollow'>
          {item?.image ? (
            <img src={`${APP_IMG}${item?.image}`} alt={item?.title || `affaliate-${_position}`} />
          ) : (
            <div className='btn-affaliate my-3' style={{ background: `${item?.bgColor}`, color: `${item?.textColor}` }}>
              {item?.title}
            </div>
          )}
        </a>
      </div>
    )
  );
};
